import './BAC.scss';
import React from 'react';

export class BAC extends React.Component {
	render() {
		return (
			<>
				<h1>BAC calendar and map sharing</h1>
				<p>To test the feasibility of BAC calendar and map sharing, please could you:</p>
				<ol>
					<li>Let me know if you can see and use the large and small map?</li>
					<li>Copy the calendar to your own, especially if you use Google Calendar but more importantly, other offerings such as Outlook Calendar. See the <a href='https://calendar.google.com/calendar/ical/7c5f4b9cea99952a14296558cf74df6c02971b52c1e765edb6d44bb33db84e0f%40group.calendar.google.com/public/basic.ics'>share calendar</a> link below the calendar.</li>
					<li>See if your shared calendar picks up new events (ask me to add one if you're not presently an admin)</li>
				</ol>
				<p>Nick, DAS may need to open a (paid) Google account to do some of this or have more control. Worth getting RealNet's opinion too.</p>
				<p>Large map example</p>
				<iframe id='largeMap' title='BAC Map - large' className='map' src="https://www.google.com/maps/d/embed?mid=1zYxa3s4_pb7S0P_WPYuRZni8aL4akiY&ehbc=2E312F" ></iframe>
				<p>Small map example</p>
				<iframe id='smallMap' title='BAC Map - small' className='map' src="https://www.google.com/maps/d/embed?mid=1zYxa3s4_pb7S0P_WPYuRZni8aL4akiY&ehbc=2E312F" ></iframe>
				<p>There would need to be a BAC gmail account, shared by all admins.</p>
				<p>Calendar example. Nick, I made you an admin, try and add an event please.</p>
				<p>Month example</p>
				<iframe
					className='calendar'
					title='BAC Calendar'
					id='calendar'
					src="https://calendar.google.com/calendar/embed?src=7c5f4b9cea99952a14296558cf74df6c02971b52c1e765edb6d44bb33db84e0f%40group.calendar.google.com&ctz=Europe%2FLondon"

				></iframe>
				<p>Week example</p>
				<iframe
					className='calendar'
					title='BAC Test Calendar'
					id='calendar'
					src="https://calendar.google.com/calendar/embed?ctz=Europe%2FLondon&showPrint=0&mode=WEEK&hl=en_GB&title=BAC%20Test%20Calendar&src=N2M1ZjRiOWNlYTk5OTUyYTE0Mjk2NTU4Y2Y3NGRmNmMwMjk3MWI1MmMxZTc2NWVkYjZkNDRiYjMzZGI4NGUwZkBncm91cC5jYWxlbmRhci5nb29nbGUuY29t&color=%234285F4"></iframe>
				<p><a href='https://calendar.google.com/calendar/ical/7c5f4b9cea99952a14296558cf74df6c02971b52c1e765edb6d44bb33db84e0f%40group.calendar.google.com/public/basic.ics'>Share this calendar</a></p>
			</>
		);
	}
}