import './Samples.scss';
import {
	Link,
} from "react-router-dom";
import {
	Odometer
}from '.';
import React from 'react';

export class Samples extends React.Component {

	render() {
		return (
			<>
				<h1>Samples</h1>
				<ol>
					<li>
						<h2>Wardrobe/Outfit prototype</h2>
						<p>This rough and ready prototype was intended to illustrate "outfit" vs "wardrobe":</p>
						<Link to="/samples/wardrobe">Wardrobe</Link>
					</li>
					<li>
						<h2>Help Popup</h2>
						<p>Another rough and ready prototype to illustrate hints that will appear to the user when they remain inactive. This just covers the structure and styling along with inheritted retailer primary colour. The backgrounds were taken directly from the design and used here to show how close the proposed solution was to the design:</p>
						<Link to="/samples/context_help" >Context Help</Link>
						<p>A form of this prototype was used on the product.</p>
					</li>
					<li>
						<h2>Zippers</h2>
						<p>A fun sample with a serious point to make:</p>
						<Link to="/samples/zippers">Zipper</Link>
					</li>
					<li>
						<h2>Half-Flap Announcement Board</h2>
						<Link to="/samples/flipboard">Board</Link>
					</li>
					<li>
						<h2>Bulbs</h2>
						<p>Pure CSS3 animation using some recursive LESS and some maths for timing.</p>
						<a href="https://www.bagnall.co.uk/oldsamples/bulbs/" target="_blank" rel="noreferrer">Bulbs</a>
					</li>
					<li>
						<h2>Wavy Banner</h2>
						<p>Here is a demonstration of using canvas to animate a banner, as used in the <a href="http://services.runescape.com/m=hiscore/heroes.ws#clans" target="_blank" rel="noreferrer">RuneScape website</a>:</p>
						<a href="http://www.bagnall.co.uk/oldsamples/wavybanner/" target="_blank" rel="noreferrer">Wavy Banner</a>
					</li>
					<li>
						<h2>Sidebar and Sorting</h2>
						<p>Responsive prototyping work completed at PrismaStar using AngularJS and isotope</p>
						<p><a href="http://www.bagnall.co.uk/prismastar/index.html" target="_blank" rel="noreferrer">Sorting and Layout prototype</a></p>
						<p><a href="http://www.bagnall.co.uk/prismastar/sidebar2.html" target="_blank" rel="noreferrer">Responsive menus prototype</a></p>
					</li>
					<li>
						<h2>New Testimonials - pure CSS3</h2>
						<p>This replaces an old javascript animation which displays quotes from employers and clients. This new version uses pure CSS3 for animation coded in LESS. It uses recursive LESS to generate a loop over each quote. It also uses mathematics in LESS to determine animation timings</p>
						<p><Link to="/">Testimonials</Link></p>
					</li>
					<li>
						<h2>Focus pull</h2>
						<p><Link to="/samples/focus/">Focus pull</Link></p>
					</li>
					<li>
						<h2>Single div spinner</h2>
						<p><Link to="/samples/spinner/">Spinner</Link></p>
					</li>
					<li>
						<h2>Odometer</h2>
						<Odometer/>
					</li>
					<li>
						<h2>D3.js</h2>
						<p><a href="http://www.bagnall.co.uk//oldsamples/d3/index3.html" target="_blank" rel="noreferrer">Simple svg d3 example</a></p>
					</li>
					<li>
						<h2>Reflective</h2>
						<p>Demonstrating use of the camera of a device to give the impression of a reflective surface. You will need to "allow" use of your camera for this to work on a compatible browser.</p>
						<p><Link to="/samples/reflective/">Reflective</Link></p>
					</li>
					<li>
						<h2>Gradient Text</h2>
						<p>There are newer techniques to achieve this but none yet, I suspect, that are completely cross browser compatible, and at the time, this saved us from an awful lot of image asset creation in 4 languages.</p>
						<p><a href="http://www.bagnall.co.uk/oldsamples/gradient_text" target="_blank" rel="noreferrer">Gradient Text</a></p>
					</li>
					<li>
						<h2>Prioritised Loading</h2>
						<p>If you have a site or a SPA (Single Page Application - a whole site hosted within one page), where much of the
							navigation and content loading is dynamic,
						{/* for example <a href='http://www.sjdesign.co.uk'>sjdesign.co.uk</a>, */}
							you may want to prioritise the order of the image loading so that those images encountered first in the navigation of the site, are loaded first, and subsequent images most likely to be encountered next, can be pre-loaded later at a lower priority. Using jQuery, this is a simple matter.</p>
						<p><a href="http://www.bagnall.co.uk/oldsamples/prioritised_loading" target="_blank" rel="noreferrer">Prioritised Loading</a></p>
					</li>
					<li>
						<h2>Atmospheric perspective</h2>
						<p>At Metail, two of the achievements I was most proud of were implementing atmospheric perspective to meet a design requirement, and click through of irregular shapes, both using canvas.</p>
						<p><a href="http://www.bagnall.co.uk/metail/canvas/canvas8.html?step=30" target="_blank" rel="noreferrer">Atmospheric perspective</a></p>
					</li>
					<li>
						<h2>Reusable elements</h2>
					</li>
				</ol>
			</>
		);
	}
}