import './VRME.scss';
import cortona from './cortona.gif';
import leedsThumb from './leeds_thumb.gif';
import React from 'react';
// import terminalEnclosure from './terminal_enclosure.gif';
import screenshot from './screenshot.gif';
import terminalEnclosureThumb from './terminal_enclosure_thumb.gif';
import videoThumb from './video_thumb.gif';
import virtualRackMountedEquipment from './v-r-m-e.gif';
import vrme from './vrme.gif';
import vrmeVideo from './vrme.avi';

export class VRME extends React.Component {

	render() {

		return (
			<div className="content">
				<center>
					<img src={vrme} alt="VRME"/>
					<img src={virtualRackMountedEquipment} alt="Virtual Rack Mounted Equipment"/>
				</center>

				<a className="blueFrame" style={{ float: `left`, margin: `0 2vw 2vw 0` }} href='leeds.gif' target='_blank' title='Click for larger view'><img src={leedsThumb} alt='Click for larger view' style={{ float: `left` }} /></a>


				<p><b><u>VRME</u></b> is an application which complements the Physical Network Inventory products from Smallworld
    GE Network Solutions.</p>

				{/* <a href='vrme.avi' target='_blank' title='Click to view a short video' className="blueFrame" style={{ float: `right`, margin: `2vw 0 2vw 2vw` }}><img src={videoThumb} alt='Click to view a short video' style={{ float: `left` }} /></a> */}
				<video
					autoPlay loop
					title='Click to view a short video'
					className="blueFrame"
					poster={videoThumb}
					style={{ float: `right`, margin: `2vw 0 2vw 2vw` }}
				>
					<source src={vrmeVideo}	type='video/avi'/>
					{/* <source src='vrme.avi'	type='video/avi'/> */}
				</video>

				<p>It goes beyond the limitations of two dimensional representations and capitalises on the value of your captured RME (Rack Mounted Equipment) data.</p>

				<p>Simply double-clicking on an object of interest such as a POP, Terminal Enclosure or Building in the GIS view, launches your preferred web browser and VRML plug in, giving you almost instant access to your RME data in 3 dimensions.</p>

				<a
					href='terminal_enclosure.gif'
					title='Click for a larger view'
					className="blueFrame"
					style={{ clear: `left`, float: `left`, margin: `0 2vw 2vw 0` }}><img src={terminalEnclosureThumb} alt='Click to view a short video' style={{ float: `left` }} /></a>

				<p style={{clear: `right`}}><b><u>VRME</u></b> offers the following benefits:</p>
				<ul>
					<li>Detailed 3d view of RME layout</li>

					<li>Produce reports and presentations for colleagues and clients</li>

					<li>Walk through a POP installation before it is built</li>

					<li>Go straight to a rack by selecting one of the automatically generated views with the same name</li>

					<li>Email a 3d report to third party</li>

					<li>Uses standard internet technology</li>
				</ul>

				<p>System Requirements:</p>

				<ul><li>Smallworld Physical Network Inventory</li></ul>

				<p>On the <a href='leeds.wrl' >next page</a> is an example of a VRML (Virtual Reality Markup Language) world, created from the Smallworld
    GE Network Solutions Physical Network Inventory rack mounted equipment data by <b><u>VRME</u></b>.</p>
				<a href='http://www.parallelgraphics.com/cortona' target='_blank' rel="noreferrer"><img src={cortona} alt='Free Download - Get Cortona VRML Client'/></a>

				<p>To view the <a href='leeds.wrl' >next page</a> you will need a plug-in for your browser.
    We recommend <a href='http://www.parallelgraphics.com/cortona' target='_blank' rel="noreferrer">Cortona3D Viewer</a> from <a href='http://www.parallelgraphics.com' target='_blank' rel="noreferrer">ParallelGraphics</a>.</p>


				<p>The first "camera" view you will see is the floorplan from above, you may move to other views using the view menu.
    All that you see has been automatically produced by <b><u>VRME</u></b>, and is activated by double clicking the hub, terminal enclosure
    or building for which the report is required.</p>

				<p>Although this is only a WWW example simulation, you may click on any of the hubs (coloured circles) in the picture below, to see how
    simple it is to generate 3d reports using <b><u>VRME</u></b>.</p>

				<center>
					{/* <img alt='Screenshot' useMap='#screenshot' src={ screenshot } /> */}
					<img alt='Screenshot' src={ screenshot } />
				</center>
				<map name="screenshot" id="screenshot">
					<area target="_blank" title="leeds exchange" alt="leeds exchange" href="leeds.wrl" coords="76,127,6" shape="circle"/>
					<area target="_blank" title="cambridge exchange" alt="cambridge exchange" href="cambridge.wrl" coords="487,155,6" shape="circle"/>
					<area target="_blank" title="terminal enclosure" alt="terminal enclosure" href="terminal.wrl" coords="236,87,6" shape="circle"/>
					<area target="_blank" title="manchester exchange" alt="manchester exchange" href="manchester.wrl" coords="601,289,6" shape="circle"/>
					<area target="_blank" title="glasgow exchange" alt="glasgow exchange" href="glasgow.wrl" coords="319,415,6" shape="circle"/>
				</map>
			</div>
		);
	}
}