import './App.scss';
import {
	BAC,
	Bookmarklets,
	Bulbs,
	ContextHelp,
	Flipboard,
	FocusPull,
	Footer,
	Header,
	Info,
	Main,
	Reflective,
	Samples,
	Spinner,
	VRME,
	Wardrobe,
	Zippers,
} from './components';
import {
	BrowserRouter,
	// Link,
	Route,
	Routes,
} from "react-router-dom";
import React from 'react';

class App extends React.Component {
	render() {
		return (
			<BrowserRouter>
				<div className="App">
					<Header />
					<div className="content">
						<Routes>
							<Route path='/' element={<Main />} />
							<Route path='/bookmarklets' element={<Bookmarklets />} />
							<Route path='/company_information' element={<Info />} />
							<Route path='/samples' element={<Samples />} />
							<Route path='/samples/bulbs' element={<Bulbs />} />
							<Route path='/samples/BAC' element={<BAC />} />
							<Route path='/samples/context_help' element={<ContextHelp />} />
							<Route path='/samples/flipboard' element={<Flipboard />} />
							<Route path='/samples/focus' element={<FocusPull />} />
							<Route path='/samples/reflective' element={<Reflective />} />
							<Route path='/samples/reflective' element={<Reflective />} />
							<Route path='/samples/spinner' element={<Spinner />} />
							<Route path='/samples/vrme' element={<VRME />} />
							<Route path='/samples/wardrobe' element={<Wardrobe />} />
							<Route path='/samples/zippers' element={<Zippers />} />
						</Routes>
					</div>
					<Footer />
				</div>
			</BrowserRouter>
		);
	}
}

export default App;
